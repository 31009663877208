import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Store } from '@ngxs/store';
import { Logout } from '../../store/app.actions';
import { UserModel } from '../../shared/models';
import { PopupFactoryService } from '../../popups/popup-factory.service';
import { MessagePopupComponent } from '../../popups/message-popup/message-popup.component';
import { Location } from '@angular/common';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private router: Router,
    private popup: PopupFactoryService,
    private location: Location,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentUser: UserModel = this.store.selectSnapshot(({ app }) => app.currentUser);
    const oldToken = this.store.selectSnapshot(({ app }) => app.oldToken);
    const newToken = this.store.selectSnapshot(({ app }) => app.newToken);
    if (currentUser && currentUser.token) {
      let currentToken: string = currentUser?.token;
      if (oldToken !== newToken) {
        currentToken = newToken;
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 && currentUser) || error.message === 'Token has expired') {
          this.store.dispatch(new Logout());
        }
        if (error.status === 403) {
          this.router.navigate(['/dashboard']);
        }
        if (error.status === 404) {
          if (
            (request.url.includes('api/users') && request.url.includes('attachment')) ||
            request.url.includes('medical-application') ||
            request.url.includes('api/users/signed')
          ) {
            this.popup
              .createPopup({
                popupComponent: MessagePopupComponent,
                preventBgClick: true,
                popupData: {
                  message: 'You have a broken link or the employee (document) has been removed',
                  yellowHeader: true,
                  closeRoute: null,
                },
              })
              .pipe(take(1))
              .subscribe(() => {
                this.location.back();
              });
          } else {
            this.router.navigate(['/dashboard/404']);
          }
        }
        if (error.status === 406) {
          if (request.url.includes('api/users/single')) {
            this.popup
              .createPopup({
                popupComponent: MessagePopupComponent,
                preventBgClick: true,
                popupData: {
                  message: 'You have a broken link or the employee (document) has been removed',
                  yellowHeader: true,
                  closeRoute: null,
                },
              })
              .pipe(take(1))
              .subscribe(() => {
                this.location.back();
              });
          }
        }
        if (error.status >= 500 && error.status <= 599 && !request?.url.includes('version')) {
          this.router.navigate(['/dashboard/500']);
        }
        return throwError(() => error);
      }),
    );
  }
}
